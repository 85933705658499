<script>
  import SimpleHeader from "../components/simpleHeader.svelte";
  import FooterBanner from "../components/footerBanner.svelte";
  import AllowScreen from "../components/allowScreen.svelte";
  import ErrorPage from "../components/ErrorPage.svelte";
  import Splash from "../components/splash.svelte";
  import { useQuery } from "@sveltestack/svelte-query";
  import api from "../resources/api";
  import { query } from "../resources/queryparams";
  import DownloadApp from "../components/DownloadApp.svelte";
  let avatar = "/assets/images/avatar.svg";

  // Apidata will give the user info
  const apidata = useQuery("people", async () => {
    const res = await api.get(
      `/user/buddy-request?${
        Object.keys(query).length === 1
          ? `hash=${query.hash}`
          : `rider_id=${query.rider_id}`
      }`
    );
    return res;
  });
  let value;
  $: {
    value = $apidata;
    console.log(value);
  }

  function hideBtns() {
    document.getElementById("allowBtn").classList.add("invisible");
    document.getElementById("allowText").classList.add("invisible");
  }

  function allowButton() {
    api
      .post(
        "/user/buddy-request",
        Object.keys(query).length === 1
          ? {
              hash: query.hash,
              status: 2,
            }
          : {
              user_id: query.user_id,
              rider_id: query.rider_id,
              status: 2,
            }
      )
      .then((res) => {
        // console.log(res);
        if (res.data.status) {
          document.getElementById("main").classList.add("hidden");
          document.getElementById("allow").classList.replace("hidden", "block");
        } else {
          document.getElementById("eContact").innerText =
            "Something Went Wrong :(";
          hideBtns();
        }
      })
      .catch((err) => {
        // console.log(err);
        if (err.response.status === 400) {
          document.getElementById("main").classList.add("hidden");
          document.getElementById("allow").classList.replace("hidden", "block");
        }
      });
  }
</script>

{#if value.status === "loading" && value.data === undefined}
  <div class="w-full h-screen">
    <Splash />
  </div>
{:else if Object.keys(query).length == 0}
  <div>
    <ErrorPage />
  </div>
{:else}
  <section class="min-h-screen overflow-hidden">
    <SimpleHeader />
    {#if value?.data?.data?.data?.is_active}
      <div>
        <div id="main" class="md:min-h-full min-h-[70vh]">
          <div
            class="flex flex-col items-center justify-center lg:pt-10 lg:pb-12 md:py-[72px] md:space-y-0 space-y-3 font-proximaBold xs:py-[85px] py-[94px]"
          >
            <img
              class="w-[90px] rounded-full"
              src={value?.data?.data?.data?.avatar
                ? value?.data?.data?.data?.avatar
                : avatar}
              alt="avatar"
            />
            <h1
              class="text-lg text-center xl:px-0 md:px-[90px] px-[45px] leading-5"
              id="eContact"
            >
              {value?.data?.data?.data?.name
                ? value?.data?.data?.data?.name
                : "Quin Designs"} wants to add you as an Emergency Contact
            </h1>
            <p
              class="text-white text-opacity-50 leading-5 text-center font-proximaRegular xl:px-[25vw] md:px-[20vw] px-[25px] py-5"
              id="allowText"
            >
              By clicking “Allow” you will be added as a Quin Emergency Contact
              for
              {value?.data?.data?.data?.name
                ? value?.data?.data?.data?.name
                : "Quin Designs"}. You will receive their location details in
              the event of an emergency.
            </p>
            <button
              on:click={allowButton}
              id="allowBtn"
              class="text-sm blue-gradient px-8 py-1 rounded-full text-white uppercase"
              >Allow</button
            >
          </div>
        </div>
        <div class="hidden" id="allow">
          <AllowScreen
            profileImage={value?.data?.data?.data?.avatar}
            name={value?.data?.data?.data?.name}
          />
        </div>
      </div>
    {:else}
      <AllowScreen
        profileImage={value?.data?.data?.data?.avatar}
        name={value?.data?.data?.data?.name}
      />
    {/if}
    <FooterBanner />
  </section>
{/if}
