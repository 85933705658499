<script>
</script>

<div
  class="pt-4 pb-6 lg:px-[140px] md:px-[95px] sm:px-[80px] xs:px-[60px] px-[30px]"
>
  <h2
    class="uppercase text-center xl:text-3xl lg:text-2xl md:text-xl text-lg font-proximaBold text-[#4ef8e8] lg:py-7 md:py-6 sm:py-5 xs:py-4 py-3"
  >
    Disclaimer
  </h2>
  <p
    class="text-center xl:text-lg lg:text-base md:text-sm text-xs font-proximaRegular px-4"
  >
    For proper functioning of Quin’s smart safety features, including but not
    limited to, SOS Beacon, Automatic Crash Detection and other emergency
    alerts, it is mandatory for the User to be running the latest version of the
    Quin Ride app, and have network and/or data connectivity, wireless access,
    or any other tele-communications services necessary for them to send alerts
    or interact with the mobile application.
    <br /><br />
    It is mandatory for you, the responder, to have network and/or data connectivity,
    wireless access, or any other tele-communications services necessary to receive
    timely alerts and/or notifications of the User’s status.
    <br /><br />
    The User assumes sole responsibility for enabling and keeping active the location
    tracking features (e.g. GPS, etc.) of their mobile device when they are using
    the App, and in no event shall Quin be responsible for any damages resulting
    from the User’s failure to do so. Quin does not guarantee the authenticity and/or
    severity of the incident, and does not guarantee that the appropriate help has
    been provided to the User. The Responder will be responsible for any and all
    actions that they take in response to this alert.
    <br /><br />
    Please read the
    <span
      ><a
        class="underline"
        href="/assets/pdfs/TermsofService.pdf"
        target="_blank">Terms of Service</a
      ></span
    >
    and
    <span
      ><a
        class="underline"
        href="/assets/pdfs/Privacy-Policy.pdf"
        target="_blank">Privacy Policy</a
      ></span
    > for a more detailed explanation.
  </p>
</div>
