<script>
  import Card from "./card.svelte";
  import { passwordSizeCheck, confirmHandler } from "../utils/validation";
  import { closeEye, openEye, tick, warning } from "../utils/images";
  import { query } from "../utils/queryparams";
  import { RESET_API } from "../utils/constants";
  import axios from "axios";
  let pclick = false;
  let reclick = false;
  let password = "";
  let repassword = "";
  let confirmStatus = false;
  //   console.log(query);
</script>

<main
  class="relative flex justify-center items-center min-h-full font-proximaRegular"
>
  {#if confirmStatus}
    <Card>
      <div class="flex justify-center items-center flex-col space-y-4 py-4">
        <img src={tick} alt="Success" class="w-16" />
        <h1
          class="text-center font-semibold uppercase w-1/2 text-3xl font-proximaBold"
        >
          Password Updated
        </h1>
        <p
          class="text-center text-white text-opacity-50 font-proximaRegular leading-5"
        >
          Your password has been updated successfully. Please use your new
          password to log in to the app.
        </p>
      </div>
    </Card>
  {:else}
    <Card>
      <div class="space-y-3">
        <h1
          class="text-2xl font-semibold uppercase md:w-[55%] w-full leading-7"
        >
          Change Password
        </h1>
        <p class="text-opacity-50 text-white leading-4 font-semibold text-sm">
          Set your new password for your Quin Ride account below.
        </p>
        <div class="pt-6 space-y-4">
          <div>
            <div
              class="w-full flex items-center pr-3 rounded-full border-[1px] p-1.5 {password !==
                '' && !passwordSizeCheck(password)
                ? 'border-[#ee4045]'
                : ''}"
            >
              <input
                type={pclick ? "text" : "password"}
                class="w-full outline-none bg-transparent px-2 placeholder:italic"
                placeholder="New Password"
                on:change={(e) => (password = e.target.value)}
              />
              <div on:click={() => (pclick = !pclick)}>
                <img
                  src={pclick ? openEye : closeEye}
                  alt="eye"
                  class="cursor-pointer"
                />
              </div>
            </div>
            {#if passwordSizeCheck(password)}
              <div
                class="text-[#ee4045] p-1 font-semibold text-sm hidden"
                id="charErr"
              >
                <h1 class="flex space-x-3">
                  <img src={warning} alt="warning" class="px-2" /> Password should
                  be in between 6 - 20 characters
                </h1>
              </div>
            {:else if password === ""}
              <div
                class="text-[#ee4045] p-1 font-semibold text-sm hidden"
                id="charErr"
              >
                <h1 class="flex space-x-3">
                  <img src={warning} alt="warning" class="px-2" /> Password should
                  be in between 6 - 20 characters
                </h1>
              </div>
            {:else}
              <div
                class="text-[#ee4045] p-1 font-semibold text-sm"
                id="charErr"
              >
                <h1 class="flex space-x-3">
                  <img src={warning} alt="warning" class="px-2" /> Password should
                  be in between 6 - 20 characters
                </h1>
              </div>
            {/if}
          </div>
          <div>
            <div
              class="w-full flex items-center pr-3 rounded-full border-[1px] p-1.5 {((repassword !==
                password &&
                !passwordSizeCheck(repassword)) ||
                repassword !== password) &&
              repassword !== ''
                ? 'border-[#ee4045]'
                : ''}"
            >
              <input
                type={reclick ? "text" : "password"}
                class="w-full outline-none bg-transparent px-2 placeholder:italic"
                placeholder="Re-Enter Password"
                on:change={(e) => (repassword = e.target.value)}
              />
              <div on:click={() => (reclick = !reclick)}>
                <img
                  src={reclick ? openEye : closeEye}
                  alt="eye"
                  class="cursor-pointer"
                />
              </div>
            </div>
            {#if repassword === ""}
              <div
                class="text-[#ee4045] p-1 font-semibold text-sm hidden"
                id="charErr"
              >
                <h1 class="flex space-x-3">
                  <img src={warning} alt="warning" class="px-2" /> Password should
                  be in between 6 - 20 characters
                </h1>
              </div>
            {:else if (repassword !== password && !passwordSizeCheck(repassword)) || !passwordSizeCheck(repassword)}
              <div class="text-[#ee4045] p-1 font-semibold text-sm">
                <h1 class="flex space-x-3">
                  <img src={warning} alt="warning" class="px-2" /> Password should
                  be in between 6 - 20 characters
                </h1>
              </div>
            {:else if repassword !== password}
              <div class="text-[#ee4045] p-1 font-semibold text-sm">
                <h1 class="flex space-x-3">
                  <img src={warning} alt="warning" class="px-2" /> Password do not
                  match!
                </h1>
              </div>
            {/if}
          </div>
        </div>
        <div class="py-3">
          <button
            class="bg-gradient-to-r from-[#27ada1] via-[#3cd5c7] to-[#4ef8e8] px-14 py-1.5 font-semibold text-lg uppercase rounded-full tracking-wider"
            on:click={() => {
              if (confirmHandler(password, repassword) && query.hash) {
                // confirmStatus = true;
                axios
                  .patch(RESET_API, {
                    password: password,
                    hash: query.hash,
                  })
                  .then(() => {
                    confirmStatus = true;
                  })
                  .catch((err) => console.log(err));
              }
            }}>Confirm</button
          >
        </div>
        {#if !confirmStatus && password !== "" && repassword !== ""}
          <h1 class="text-[#ee4045]">
            Please check the fields before clicking confirm!
          </h1>
        {/if}
      </div>
    </Card>
  {/if}
</main>
