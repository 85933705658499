<script>
  import { loader } from "../resources/gmaploader.js";
  import { onMount } from "svelte";
  import { mapStyles } from "../resources/mapstyles";
  import { io } from "socket.io-client";
  import { query } from "../resources/queryparams";
  import { BASE_SOCKET_URL } from "../utils/constants"

  let gmap, map, data, markerdata;
  const markerImg = "/assets/images/sosmarker.svg";

  export let location;
  export let marker;

  data = {
    location: location,
  };

  onMount(() => {
    let responderLocation = {
      lat: 0,
      lng: 0,
    };

    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition((position) => {
        responderLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        // console.log(responderLocation);
      });
    } else {
      // console.log("hello");
    }

    loader.load().then(() => {
      // console.log(data)
      let userLocation = {
        lat: parseFloat(data.location.lat),
        lng: parseFloat(data.location.lng),
      };

      // Map Init
      const mapOptions = {
        center: userLocation,
        zoom: 14,
        streetViewControl: false,
        mapTypeControl: false,
        disableDefaultUI: true,
        styles: mapStyles[1],
        // zoomControl: true,
        // zoomControlOptions: {
        //   position: google.maps.ControlPosition.LEFT_TOP,
        // },
        // fullscreenControl: true,
      };

      map = new google.maps.Map(gmap, mapOptions);

      // Map controls
      // map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(container);

      // Map markers
      markerdata = new google.maps.Marker({
        position: userLocation,
        map: map,
        icon: {
          anchor: new google.maps.Point(60, 60),
          url: marker ? marker : markerImg,
          scaledSize: new google.maps.Size(120, 120),
        },
        optimized: false,
      });

      // Sockets

      const socket = io.connect( BASE_SOCKET_URL, {
        transports: ["websocket"],
      });

      // Socket connection
      socket.on("connect", () => {
        // console.log("socket connected!", socket);
        socket.on(query.event_id, (data) => {
          // console.log("recieving");
          var loc = data?.locations;
          var len = loc.length - 1;
          var location = new google.maps.LatLng(
            parseFloat(loc[len]?.lat),
            parseFloat(loc[len]?.lng)
          );
          map.setCenter(location);
          markerdata.setPosition(location);
        });
      });

      // Socket error
      socket.on("error", (error) => {
        alert("Error occured!");
      });

      // Socket Disconnect
      socket.on("disconnect", (reason) => {
        if (reason === "io server disconnect") {
          // the disconnection was initiated by the server, you need to reconnect manually
          socket.connect();
        }
        // else the socket will automatically try to reconnect
      });
    });
  });
</script>

<div
  class="lg:mb-0 mb-1 bg-gray-400 lg:rounded-md lg:min-h-full min-h-[75vh]"
  bind:this={gmap}
/>
