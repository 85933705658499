<script>
  let onelink = "/assets/images/onelink.svg";
</script>

<div class="text-white">
  <div
    class="flex flex-col justify-center items-center w-full space-y-4 font-proximaRegular py-6 md:px-0 px-4  md:min-h-full min-h-[75vh]"
  >
    <h1
      class="md:text-3xl sm:text-2xl xs:text-xl 2xs:text-base text-sm font-black font-proximaBold"
    >
      Download the Quin Ride App for free
    </h1>
    <p
      class="font-bold font-proximaBold sm:text-sm xs:text-xs 2xs:text-2xs text-3xs"
    >
      Scan the QR code to download
    </p>
    <img src={onelink} alt="onelink" />
    <div>
      <ul
        class="space-y-4 font-medium sm:text-sm xs:text-xs 2xs:text-2xs text-3xs"
      >
        <li class="list-disc">Open the camera app on your phone.</li>
        <li class="list-disc">Focus the camera on the QR code</li>
        <li class="list-disc">
          Tap the notification that pops up to open the link.
        </li>
      </ul>
    </div>
  </div>
</div>
