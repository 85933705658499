<script>
  import ChangePassword from "../components/changePassword.svelte";
  import Placeholder from "../components/placeholder.svelte";
</script>

<main>
  <Placeholder>
    <ChangePassword />
  </Placeholder>
</main>
