// import SosResponderMap from "../pages/SosRespondermap.svelte";
// import CrashResponderMap from "../pages/CrashRespondermap.svelte";
import OnBoardingResponder from "../pages/onboardingResponder.svelte";
// import SosCopy from "../pages/sosCopy.svelte";
// import MarkSafe from "../components/marksafe.svelte";
import AllowScreen from "../components/allowScreen.svelte";
// import MarksafeSuccess from "../components/marksafeSuccess.svelte";
import Crash from "../pages/crash.svelte";
import ErrorPage from "../components/ErrorPage.svelte";
import Sos from "../pages/sos.svelte";
import Splash from "../components/splash.svelte";
import ResetPassword from "../pages/resetPassword.svelte";
import EmailSignatureNoImg from "../components/emailSignatureNoImg.svelte";
import EmailSign from "../pages/EmailSign.svelte";
export default [
  {
    path: "/",
    component: OnBoardingResponder,
  },
  // {
  //   path: "/sos",
  //   component: SosResponderMap,
  // },
  // {
  //   path: "/crash",
  //   component: CrashResponderMap,
  // },
  // {
  //   path: "/marksafe",
  //   component: MarkSafe,
  // },
  {
    path: "/allow",
    component: AllowScreen,
  },
  // {
  //   path: "/sucess",
  //   component: MarksafeSuccess,
  // },
  {
    path: "/crash",
    component: Crash,
  },
  {
    path: "/sos",
    component: Sos,
  },
  {
    path: "/splash",
    component: Splash,
  },
  {
    path: "/forgot-password",
    component: ResetPassword,
  },
  {
    path: "/email-signature-noimg",
    component: EmailSignatureNoImg,
  },
  {
    path: "/email-signature",
    component: EmailSign,
  },
  {
    path: "*",
    component: ErrorPage,
  },
];
