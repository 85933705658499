function dateTimeConverion(timestamp) {
  let value = new Date(timestamp);
  return (
    value.toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    }) +
    " " +
    value.toLocaleTimeString(undefined, { hour: "2-digit", minute: "2-digit" })
  );
}

function bloodgroupValue(type) {
  switch (type) {
    case 1:
      return "A+";
      break;
    case 2:
      return "A-";
      break;
    case 3:
      return "B+";
      break;
    case 4:
      return "B-";
      break;
    case 5:
      return "O+";
      break;
    case 6:
      return "O-";
      break;
    case 7:
      return "AB+";
      break;
    case 8:
      return "AB-";
      break;
    default:
      return "Unspecified";
      break;
  }
}

function helmetBatteryStatus(key) {
  switch (key) {
    case 1:
      return "Low";
      break;
    case 2:
      return "Medium";
      break;
    case 3:
      return "High";
      break;

    default:
      return "NA";
      break;
  }
}

function crashType(key) {
  switch (key) {
    case "1":
      return "Crash";
      break;
    case 1:
      return "Crash";
      break;
    // case 2:
    //   return "Passive Crash";
    //   break;
    case "3":
      return "SOS";
      break;
    case 3:
      return "SOS";
      break;
    // case 4:
    //   return "Responder Added";
    //   break;
    // case 5:
    //   return "Responder Removed";
    //   break;

    default:
      return "NA";
      break;
  }
}

function roundoff(value, digits) {
  return digits === 0
    ? Math.round(value)
    : Math.round((value + Number.EPSILON) * (10 * digits)) / (10 * digits);
}

module.exports = {
  dateTimeConverion,
  bloodgroupValue,
  helmetBatteryStatus,
  crashType,
  roundoff,
};
