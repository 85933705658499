<script>
  import SimpleHeader from "./simpleHeader.svelte";
  import FooterBanner from "./footerBanner.svelte";
  export let values;
  export let profileimg;
  export let name;
  let finalData;

  let avatar = "/assets/images/avatar.svg";
  $: {
    finalData = !values ? "No Data Selected" : values.join(", ");
    // console.log(finalData);
  }
</script>

<main>
  <SimpleHeader />
  <div
    class="flex flex-col justify-center items-center font-proximaRegular space-y-8 xl:px-[30vw] lg:px-40 md:px-30 px-6 py-8 md:min-h-full min-h-[75vh]"
  >
    <img
      src={profileimg ? profileimg : avatar}
      alt="avatar"
      class="w-24 rounded-full"
    />
    <h1 class="font-proximaBold text-3xl">
      You've marked {name ? name : "NA"} safe
    </h1>
    <p>
      You've selected
      <span class="font-proximaBold"> {finalData} </span>
      to mark {name} safe.
    </p>
  </div>
  <FooterBanner />
</main>
