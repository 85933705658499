<script>
  import { useQuery } from "@sveltestack/svelte-query";
  import axios from "axios";

  import Maps from "../components/maps.svelte";
  import Disclaimer from "../components/disclaimer.svelte";
  import FooterBanner from "../components/footerBanner.svelte";
  import Profile from "../components/profile.svelte";
  import MarksafeSuccess from "../components/marksafeSuccess.svelte";

  import { MARK_SAFE_API }  from "../utils/constants"

  import { crash_store } from "../store/index";
  import api from "../resources/api";
  import { query } from "../resources/queryparams";
  import Splash from "../components/splash.svelte";

  let crashLogo = "/assets/images/Crash-Alert.webp";
  let crashMarker = "/assets/images/marker.gif";
  let avatar = "/assets/images/avatar.svg";
  let cross = "/assets/images/Cross_Icon.svg";
  let option1, option2, option3, option4, option5, popupStatus, crossStatus;
  option1 =
    option2 =
    option3 =
    option4 =
    option5 =
    popupStatus =
    crossStatus =
      false;
  let crashData,
    label = [];
  let crashProp = {};

  const crash = useQuery("crash", async () => {
    const response = await api.get(`/events?id=${query.event_id}`);
    return response;
  });

  $: {
    crashData = $crash;
    $crash_store = crashData;
    crashProp = {
      avatar: crashData?.data?.data?.data?.event?.avatar
        ? crashData?.data?.data?.data?.event?.avatar
        : "",
      blood_group: crashData?.data?.data?.data?.event?.blood_group,
      country_code: crashData?.data?.data?.data?.event?.country_code,
      helmet: crashData?.data?.data?.data?.event?.helmet,
      impact_index: crashData?.data?.data?.data?.event?.impact_index
        ? crashData?.data?.data?.data?.event?.impact_index
        : 0,
      inserted_at: crashData?.data?.data?.data?.event?.inserted_at,
      locations: crashData?.data?.data?.data?.event?.locations,
      mobile: crashData?.data?.data?.data?.event?.mobile,
      name: crashData?.data?.data?.data?.event?.name,
      type: crashData?.data?.data?.data?.event?.type,
      mobile_number: crashData?.data?.data?.data?.event?.mobile_number,
    };
  }

  // Mark safe functions

  function emergencyServices() {
    option1 = !option1;
    if (option1) {
      label = [
        ...label,
        document.getElementById("emergency-services").innerText,
      ];
    } else {
      var idx = label.indexOf(
        document.getElementById("emergency-services").innerText
      );
      label = [...label.slice(0, idx), ...label.slice(idx + 1)];
    }
  }

  function withRider() {
    option2 = !option2;
    if (option2) {
      label = [...label, document.getElementById("with-rider").innerText];
    } else {
      var idx = label.indexOf(document.getElementById("with-rider").innerText);
      label = [...label.slice(0, idx), ...label.slice(idx + 1)];
    }
  }

  function somebodyNearby() {
    option3 = !option3;
    if (option3) {
      label = [...label, document.getElementById("somebody-nearby").innerText];
    } else {
      var idx = label.indexOf(
        document.getElementById("somebody-nearby").innerText
      );
      label = [...label.slice(0, idx), ...label.slice(idx + 1)];
    }
  }

  function talkedRider() {
    option4 = !option4;
    if (option4) {
      label = [...label, document.getElementById("talked-rider").innerText];
    } else {
      var idx = label.indexOf(
        document.getElementById("talked-rider").innerText
      );
      label = [...label.slice(0, idx), ...label.slice(idx + 1)];
    }
  }

  function calledES() {
    option5 = !option5;
    if (option5) {
      label = [...label, document.getElementById("called-es").innerText];
    } else {
      var idx = label.indexOf(document.getElementById("called-es").innerText);
      label = [...label.slice(0, idx), ...label.slice(idx + 1)];
    }
  }

  function marksafe() {
    axios
      .post(MARK_SAFE_API, {
        hash_value: query?.hash ? query?.hash : "",
        event_id: query?.event_id,
        mark_safe: true,
        user_id: query?.user_id ? query?.user_id : "",
      })
      .then((res) => {
        // alert("event closed");
        document.getElementById("active").classList.replace("block", "hidden");
        document.getElementById("popup").classList.replace("hidden", "block");
        // console.log(res);
      })
      .catch((err) => console.log(err));
  }
</script>

{#if $crash.status === "loading"}
  <Splash />
{:else}
  <section id="active" class="block">
    <div>
      <div>
        <div>
          <nav
            class="blue-gradient flex justify-between items-center md:px-10 px-4 "
          >
            <div
              class="flex md:space-x-3 md:py-8 py-5 space-x-1.5 items-center"
            >
              <img
                src={crashLogo}
                alt="quin-logo"
                class="md:w-[260px] w-[160px]"
              />
            </div>
            <div>
              <button
                on:click={() => {
                  popupStatus = !popupStatus;
                }}
                class="py-1 md:px-7 px-4 sm:text-sm lg:text-base text-xs rounded-full bg-white text-[#27ada1] font-bold uppercase"
                >Mark safe</button
              >
            </div>
          </nav>
        </div>
      </div>
      <div>
        <div>
          <div
            class="flex lg:p-4 lg:pb-4 lg:flex-row flex-col w-full 2xl:min-h-[65vh] xl:min-h-[70vh] overflow-hidden"
          >
            <div class="lg:min-w-[calc(100vw_-_500px)] md:min-h-auto">
              <Maps
                location={{
                  lat: crashProp.locations[0].lat,
                  lng: crashProp.locations[0].lng,
                }}
                marker={crashMarker}
              />
            </div>
            <div>
              <Profile profileProps={crashProp} />
            </div>
          </div>
          <div>
            <Disclaimer />
          </div>
          <div>
            <FooterBanner />
          </div>
        </div>
      </div>
    </div>
    <div>
      <div
        id="modal"
        class={`bg-[#3a3a3c] min-h-[300vh] backdrop-blur-[40px] bg-opacity-10 z-20 absolute inset-0 justify-center items-center ${
          popupStatus ? "flex" : "hidden"
        }`}
      >
        <!-- WEB POPUP -->
        <div class="relative min-h-[100vh] -top-1/4 md:block hidden">
          <img
            src="/assets/images/marksafe-bg-web.svg"
            alt="MarksafeBg"
            class="w-3/4 mx-auto"
          />
          <div class="absolute inset-0 mx-auto w-3/4">
            <div class="">
              <div class="w-full py-3 px-5 flex justify-between text-white">
                <div class="flex space-x-8 items-center lg:pt-4 md:pt-1 ">
                  <div
                    class="w-16 h-16 rounded-full flex justify-center items-center"
                  >
                    <img
                      src={crashProp?.avatar ? crashProp?.avatar : avatar}
                      alt="avatar"
                      class="md:w-24 w-16 rounded-full"
                    />
                  </div>
                  <div>
                    <h1
                      class="font-bold lg:text-3xl md:text-2xl sm:text-xl text-lg "
                    >
                      MARK SAFE
                    </h1>
                    <p class="font-bold md:text-base xs:text-sm text-xs">
                      IS <span class="uppercase">{crashProp?.name}</span> SAFE?
                    </p>
                    <p class="md:text-base xs:text-sm text-xs">
                      Select whatever is applicable
                    </p>
                  </div>
                </div>
                <!-- Cross button -->
                <div class="absolute -right-0 -top-0">
                  <button
                    class="bg-black bg-opacity-50 p-1 rounded-full"
                    on:click={() => {
                      popupStatus = !popupStatus;
                    }}
                  >
                    <img src={cross} alt="cross" class="w-5" />
                  </button>
                </div>
              </div>
            </div>
            <div class="p-3">
              <div
                class="lg:py-4 md:py-2 flex flex-wrap font-semibold text-opacity-50 md:text-base text-xs"
              >
                <button
                  id="emergency-services"
                  on:click={emergencyServices}
                  class={`my-2 mx-3 rounded-md px-4 border-[#ffffff]/[.55] ${
                    option1
                      ? "blue-gradient text-white border-0 py-2"
                      : "text-white text-opacity-50 border-2 py-1"
                  }`}>I am with the emergency services personnel</button
                >
                <button
                  id="with-rider"
                  on:click={withRider}
                  class={`my-2 mx-3 rounded-md px-4 border-[#ffffff]/[.55] ${
                    option2
                      ? "blue-gradient text-white border-0 py-2"
                      : "text-white text-opacity-50 border-2 py-1"
                  }`}>I am with the rider</button
                >
                <button
                  id="somebody-nearby"
                  on:click={somebodyNearby}
                  class={`my-2 mx-3 rounded-md px-4 border-[#ffffff]/[.55] ${
                    option3
                      ? "blue-gradient text-white border-0 py-2"
                      : "text-white text-opacity-50 border-2 py-1"
                  }`}>I informed somebody who is nearby</button
                >
                <button
                  id="talked-rider"
                  on:click={talkedRider}
                  class={`my-2 mx-3 rounded-md px-4 border-[#ffffff]/[.55] ${
                    option4
                      ? "blue-gradient text-white border-0 py-2"
                      : "text-white text-opacity-50 border-2 py-1"
                  }`}>I talked to the rider over the phone</button
                >
                <button
                  id="called-es"
                  on:click={calledES}
                  class={`my-2 mx-3 rounded-md px-4 border-[#ffffff]/[.55] ${
                    option5
                      ? "blue-gradient text-white border-0 py-2"
                      : "text-white text-opacity-50 border-2 py-1"
                  }`}>I called Emergency Services</button
                >
              </div>
              <div class="p-3 lg:mt-4 md:mt-0">
                <button
                  on:click={marksafe}
                  class="blue-gradient text-white text-base px-8 rounded-full py-1 font-semibold"
                  >MARK SAFE</button
                >
              </div>
            </div>
          </div>
        </div>
        <!-- MOBILE POPUP -->
        <div
          class="relative min-h-[100vh] -top-1/4 md:hidden block mxs:m-0 m-3"
        >
          <img
            src="/assets/images/marksafe-bg-mobile.svg"
            alt="MarksafeBg"
            class="w-full mx-auto"
          />
          <div class="absolute inset-0 mx-auto w-full">
            <div class="">
              <div class="w-full py-3 px-5 flex justify-between text-white">
                <div class="flex space-x-4 items-center ">
                  <div class="rounded-full flex justify-center items-center">
                    <img
                      src={crashProp?.avatar ? crashProp?.avatar : avatar}
                      alt="avatar"
                      class="w-14 rounded-full"
                    />
                  </div>
                  <div>
                    <h1
                      class="font-bold lg:text-3xl md:text-2xl sm:text-xl text-lg "
                    >
                      MARK SAFE
                    </h1>
                    <p class="font-bold md:text-base xs:text-sm text-xs">
                      IS <span class="uppercase">{crashProp?.name}</span> SAFE?
                    </p>
                    <p class="md:text-base xs:text-sm text-xs">
                      Select whatever is applicable
                    </p>
                  </div>
                </div>
                <!-- Cross button -->
                <div class="absolute -right-0 -top-0.5">
                  <button
                    class="bg-black bg-opacity-50 p-1 rounded-full"
                    on:click={() => {
                      popupStatus = !popupStatus;
                    }}
                  >
                    <img src={cross} alt="cross" class="w-8" />
                  </button>
                </div>
              </div>
            </div>
            <div class="2xs:p-3 px-2">
              <div
                class="lg:py-4 md:py-2 flex flex-wrap font-semibold text-opacity-50 md:text-base text-xs"
              >
                <button
                  id="emergency-services"
                  on:click={emergencyServices}
                  class={`my-2 mx-1 rounded-md px-4 border-[#ffffff]/[.55] ${
                    option1
                      ? "blue-gradient text-white border-0 py-1"
                      : "text-white text-opacity-50 border-2 py-1"
                  }`}>I am with the emergency services personnel</button
                >
                <button
                  id="with-rider"
                  on:click={withRider}
                  class={`my-2 mx-1 rounded-md px-4 border-[#ffffff]/[.55] ${
                    option2
                      ? "blue-gradient text-white border-0 py-1"
                      : "text-white text-opacity-50 border-2 py-1"
                  }`}>I am with the rider</button
                >
                <button
                  id="called-es"
                  on:click={calledES}
                  class={`my-2 mx-1 rounded-md px-4 border-[#ffffff]/[.55] ${
                    option5
                      ? "blue-gradient text-white border-0 py-1"
                      : "text-white text-opacity-50 border-2 py-1"
                  }`}>I called Emergency Services</button
                >
                <button
                  id="somebody-nearby"
                  on:click={somebodyNearby}
                  class={`my-2 mx-1 rounded-md px-4 border-[#ffffff]/[.55] ${
                    option3
                      ? "blue-gradient text-white border-0 py-1"
                      : "text-white text-opacity-50 border-2 py-1"
                  }`}>I informed somebody who is nearby</button
                >
                <button
                  id="talked-rider"
                  on:click={talkedRider}
                  class={`my-2 mx-1 rounded-md px-4 border-[#ffffff]/[.55] ${
                    option4
                      ? "blue-gradient text-white border-0 py-1"
                      : "text-white text-opacity-50 border-2 py-1"
                  }`}>I talked to the rider over the phone</button
                >
              </div>
              <div class="p-3 lg:mt-4 md:mt-0">
                <button
                  on:click={marksafe}
                  class="blue-gradient text-white text-sm px-8 rounded-full py-1 font-semibold"
                  >MARK SAFE</button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="popup" class="hidden">
    <MarksafeSuccess
      values={label}
      name={crashProp?.name}
      profileimg={crashProp?.avatar}
    />
  </section>
{/if}

<!-- 
    Sockets for SOS
   import { io } from "socket.io-client";

   const socket = io.connect("http://65.0.90.255:4004", {
     transports: ["websocket"],
   });
   socket.on("connect", () => {
     console.log("socket connected!", socket);
     socket.on(query.event_id, (data) => {
       console.log("recieving");
       console.log(data);
     });
   });
 -->
