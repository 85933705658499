export let user = [
  {
    address: "1729 Irving Blvd, Suite 103 Dallas, TX 75243 USA",
    contact: ["P: +1 (214) 855 8785", "M: +1 (701) 800 3004"],
    fullname: "ANI SURABHI",
    image: "/assets/images/email/Ani.png",
    name: ["ANI", "SURABHI"],
    position: "FOUNDER | CEO | CHIEF DESIGNER",
  },
  {
    address: "1729 Irving Blvd, Suite 103 Dallas, TX 75243 USA",
    contact: ["P: +1 (214) 855 8785", "M: +1 (701) 800 3131"],
    fullname: "HANNAH SURABHI",
    image: "/assets/images/email/Hannah.png",
    name: ["HANNAH", "SURABHI"],
    position: "CHIEF OPERATING OFFICER",
  },
  {
    address: "1729 Irving Blvd, Suite 103 Dallas, TX 75243 USA",
    contact: ["P: +1 (214) 855 8785", "M: +1 (469) 831 9570"],
    fullname: "CONRAD LEWIS",
    image: "/assets/images/email/Conrad.png",
    name: ["CONRAD", "LEWIS"],
    position: "COMMERCIAL DIRECTOR",
  },
  {
    address: "1729 Irving Blvd, Suite 103 Dallas, TX 75243 USA",
    contact: ["P: +1 (214) 855 8785", "", "M: +1 (214) 265 8821"],
    fullname: "MIKE MOORE",
    image: "/assets/images/email/Mike.png",
    name: ["MIKE", "MOORE"],
    position: "NATIONAL SALES MANAGER",
  },
  {
    address: "1729 Irving Blvd, Suite 103 Dallas, TX 75243 USA",
    contact: ["P: +1 (214) 855 8785", "M: +1 (469) 859 7077"],
    fullname: "GINO VILLANUEVA",
    image: "/assets/images/email/Gino.png",
    name: ["GINO", "VILLANUEVA"],
    position: "RIDER SUPPORT OFFICER",
  },
  {
    address: "1729 Irving Blvd, Suite 103 Dallas, TX 75243 USA",
    contact: ["P: +1 (214) 855 8785"],
    fullname: "RIDER SUPPORT",
    image: "/assets/images/email/Rider.png",
    name: ["RIDER", "SUPPORT"],
    position: null,
  },
  {
    address: "Dallas X Hyderabad",
    contact: ["M: +91 88908 48385"],
    fullname: "AMEER SOHAIL",
    image: "/assets/images/email/Ameer.png",
    name: ["AMEER", "SOHAIL"],
    position: "HEAD OF ENGINEERING",
  },
  {
    address: "Dallas X Hyderabad",
    contact: ["M: +91 98855 51466"],
    fullname: "DAMAN PATEL",
    image: "/assets/images/email/Daman.png",
    name: ["DAMAN", "PATEL"],
    position: "HEAD OF OPERATIONS | INDIA",
  },
  {
    address: "Dallas X Hyderabad",
    contact: ["M: +91 96769 60126"],
    fullname: "RAGHAVA PRAMOD",
    image: "/assets/images/email/Raghava.png",
    name: ["RAGHAVA", "PRAMOD"],
    position: "HUMAN RESOURCE MANAGER",
  },
  {
    address: "Dallas X Hyderabad",
    contact: ["M: +91 80197 12445"],
    fullname: "PHANI UPADHYAYULA",
    image: "/assets/images/email/Phani.png",
    name: ["PHANI", "UPADHYAYULA"],
    position: "PROJECT MANAGER",
  },
];
