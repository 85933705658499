<script>
  import {
    dateTimeConverion,
    bloodgroupValue,
    helmetBatteryStatus,
    crashType,
    roundoff,
  } from "../resources/handyfuncs";
  let avatar = "/assets/images/avatar.svg";
  let helmetimg = "/assets/images/image-not-found.svg";
  let callicon = "/assets/images/call-icon.svg";
  export let profileProps;
  // console.log(profileProps);
  // console.log(crashType(profileProps.type));
</script>

<main>
  <div
    id="container"
    class="flex lg:flex-col flex-col-reverse font-proximaRegular text-base lg:pl-6 px-4 lg:pt-0 lg:min-w-[350px] pt-4"
  >
    <div
      class="space-y-4 p-6 pt-6 pb-10 bg-black mb-4 rounded-md shadow-[0_1.5px_3px_0px_rgba(0,0,0,0.16)]"
    >
      <h1 class={`text-[#4ef8e8] font-proximaBold sm:text-xl text-lg`}>
        {crashType(profileProps?.type) === "SOS"
          ? "SOS Beacon (Live)"
          : "Crash Alert"}
      </h1>
      <div class="flex font-proximaBold items-center justify-between">
        <div class="flex justify-center items-center space-x-4">
          <img
            class="w-[40px] h-[40px] rounded-full"
            src={profileProps?.avatar ? profileProps?.avatar : avatar}
            alt="Profile pic"
          />
          <div class="overflow-hidden">
            <h1 class="max-w-[40vw] truncate sm:text-base text-sm">
              {profileProps?.name}
            </h1>
            <h1 class="text-[#4ef8e8] sm:text-base text-xs">
              <!-- {bloodgroupValue(profileProps?.blood_group)} Blood Group -->
              {bloodgroupValue(profileProps?.blood_group) !== "Unspecified"
                ? `${bloodgroupValue(profileProps?.blood_group)} Blood Group`
                : "Unavailable"}
            </h1>
          </div>
        </div>
        <div
          class="px-6 py-1 border-2 text-sm border-[#4ef8e8] text-[#4ef8e8] rounded-full"
        >
          <a
            href={`tel:${profileProps?.country_code}${profileProps?.mobile_number}`}
          >
            <p class="uppercase">Call Now</p>
            <img src={callicon} alt="call" class="hidden w-4"/>
          </a>
        </div>
      </div>
      <div>
        <p class="leading-5">
          <span class="font-proximaBold">{profileProps?.name}</span>
          {crashType(profileProps?.type) === "SOS"
            ? "has triggered an "
            : "had a "}
          <span class={`font-proximaBold text-[#4ef8e8]`}>
            {crashType(profileProps?.type) === "SOS"
              ? "SOS Beacon"
              : "Crash "}</span
          > and might requires your assistance. Tap on call now to connect with your
          friend.
        </p>
      </div>
      <div class="space-y-2">
        <div class="flex justify-between items-center font-proximaBold ">
          <p class="text-[#ffffff] text-opacity-50">Phone Details</p>
          <div class="w-[60%] h-[1.5px] bg-[#fff] bg-opacity-20" />
        </div>
        <div class="flex justify-between items-center font-proximaBold">
          <div>
            <p class="capitalize">{profileProps?.mobile?.name}</p>
            <p class="text-xs font-proximaRegular text-white text-opacity-50">
              Phone Model
            </p>
          </div>
          <div>
            <p class="text-[#4ef8e8] text-lg uppercase text-right">
              {roundoff(profileProps?.mobile?.battery, 0)}%
            </p>
            <p class="text-xs font-proximaRegular text-white text-opacity-50">
              Phone Battery
            </p>
          </div>
        </div>
        <div class="flex justify-between items-center font-proximaBold ">
          <p class="text-[#ffffff] text-opacity-50">Helmet Details</p>
          <div class="w-[60%] h-[1.5px] bg-[#fff] bg-opacity-20" />
        </div>
        <div class="flex justify-between items-center font-proximaBold ">
          <div class="flex items-center space-x-4">
            <div class="rounded-full">
              <img
                class="w-10"
                src={profileProps?.helmet?.url
                  ? profileProps?.helmet?.url
                  : helmetimg}
                alt="helmet"
              />
            </div>
            <div>
              <p>
                {typeof profileProps?.helmet?.name === "undefined"
                  ? "NA"
                  : profileProps?.helmet?.name}
              </p>
              <p class="text-xs font-proximaRegular text-white text-opacity-50">
                Helmet Model
              </p>
            </div>
          </div>
          <div>
            <p class="text-[#4ef8e8] text-lg text-right uppercase">
              {helmetBatteryStatus(profileProps?.helmet?.battery)}
            </p>
            <p class="text-xs font-proximaRegular text-white text-opacity-50">
              Helmet Battery
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="lg:px-6 lg:my-0 mb-4 px-4 w-full bg-[#000] py-4 rounded-md shadow-[0_1.5px_3px_0px_rgba(0,0,0,0.2)] text-white text-sm font-proximaRegular space-y-4"
    >
      <div class="flex justify-between items-center">
        <div class="font-proximaBold text-base">
          <p>{crashType(profileProps?.type)} Triggered on :</p>
          <p class="text-sm md:text-base">
            {dateTimeConverion(
              profileProps?.locations[profileProps?.impact_index]?.timestamp
            )}
          </p>
        </div>
        <div class="px-6 py-1 border-2 border-[#4ef8e8] rounded-full">
          <button class="uppercase text-[#4ef8e8] font-bold">
            <a
              href={`https://www.google.com/maps/search/${
                profileProps?.locations[profileProps?.impact_index]?.lat
              },${profileProps?.locations[profileProps?.impact_index]?.lng}`}
              target="_blank"
              rel="noopener noreferrer">Navigate</a
            >
          </button>
        </div>
      </div>
      <div
        class={`${
          crashType(profileProps?.type) === "SOS" ? "block" : "hidden"
        }`}
      >
        Pressing the navigate button will direct you to the last updated
        location of the rider.
      </div>
    </div>
    <div />
  </div>
</main>
