<script>
  import { QueryClient, QueryClientProvider } from "@sveltestack/svelte-query";

  import router from "page";
  import routes from "./resources/routes";

  let page;
  let params;

  const queryClient = new QueryClient();

  routes.forEach((route) => {
    router(
      route.path,
      (ctx, next) => {
        params = ctx.params ? ctx.params : null;
        next();
      },
      () => {
        page = route.component;
      }
    );
  });

  router.start();
</script>

<QueryClientProvider client={queryClient}>
  <!-- if params needed uncomment the below line -->
  <!-- <svelte:component this={page} {params} /> -->

  <!-- if params not needed uncomment the below line -->
  <svelte:component this={page} />
</QueryClientProvider>
