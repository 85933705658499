<script>
  import SimpleHeader from "../components/simpleHeader.svelte";
  import FooterBanner from "../components/footerBanner.svelte";
</script>

<main class="bg-[#202024] bg-center bg-no-repeat w-full">
  <div>
    <SimpleHeader />
  </div>
  <!-- ====== Error 404 Section Start -->
  <section
    class="py-[120px] relative z-10 font-proximaRegular overflow-hidden sm:min-h-full min-h-[75vh]"
  >
    <div class="container">
      <div class="flex -mx-4">
        <div class="w-full px-4">
          <div class="mx-auto max-w-[400px] text-center">
            <h2
              class="
                  font-bold
                  
                  mb-2
                  text-[50px]
                  sm:text-[80px]
                  md:text-[100px]
                  leading-none
                  "
            >
              404
            </h2>
            <h4 class=" font-semibold text-[22px] leading-tight mb-3">
              Oops! That page can’t be found
            </h4>
            <p class="text-lg  mb-8">
              The page you are looking for is not available.
            </p>
            <a
              href="https://www.quin.design/"
              class="
                  text-base
                  font-semibold
                  
                  inline-block
                  text-center
                  border border-[#555]
                  rounded-lg
                  px-8
                  py-3
                  hover:bg-white hover:text-black
                  transition
                  "
            >
              Checkout Quin Design
              
            </a>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
      absolute
      -z-10
      w-full
      h-full
      top-0
      left-0
      flex
      justify-between
      items-center
      space-x-5
      md:space-x-8
      lg:space-x-14
      "
    >
    </div>
  </section>
  <!-- ====== Error 404 Section End -->
  <div>
    <FooterBanner />
  </div>
</main>
