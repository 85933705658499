<script>
  let name = [];
  let contact = [];
  let firstname, lastname, position, address, mobile, phone;
  let status = false;
  const formHandler = (e) => {
    e.preventDefault();
    if (firstname && lastname && address && mobile) {
      console.log(firstname, lastname, position, address, mobile, phone);
      name[0] = firstname;
      name[1] = lastname;
      contact[0] = mobile;
      contact[1] = phone;
      status = true;
    } else {
      alert("Fill all the mandatory fields");
    }
  };
</script>

<section>
  {#if status}
    <div class="min-h-screen text-black bg-white m-0 p-0">
      <main
        class="border-[1px] px-4 pt-4 text-[#707070] w-[600px] font-proximaRegular mx-auto my-8 relative"
      >
        <div class="w-full">
          <div class="flex space-x-6 justify-start">
            <div>
              <img
                src="/assets/images/email-logo.gif"
                alt="logo"
                class="max-w-[75px]"
              />
            </div>
            <div>
              <h1
                class="text-3xl text-[#707070] font-proximaRegular font-semibold leading-8 uppercase"
              >
                {name[0]} <br />
                {name[1]}
              </h1>
              <p class="font-proximaRegular text-[12px] font-semibold">
                {position !== undefined && position !== null ? position : ""}
              </p>
            </div>
          </div>
          <div class="py-3 flex space-x-2">
            <a href="https://www.instagram.com/quin.design/?hl=en">
              <img
                src="/assets/images/instagram.svg"
                alt="icons"
                class="w-[14px]"
              />
            </a>
            <a href="https://in.linkedin.com/company/quindesign">
              <img
                src="/assets/images/linkedin.svg"
                alt="icons"
                class="w-[14px]"
              />
            </a>
            <a href="https://www.facebook.com/Quin.DesignFB/">
              <img
                src="/assets/images/facebook.svg"
                alt="icons"
                class="w-[14px]"
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UC23UdFuJzMpQ9IfIBxVW_Ww/playlists?app=desktop"
            >
              <img
                src="/assets/images/youtube.svg"
                alt="icons"
                class="w-[14px]"
              />
            </a>
          </div>
          <div class="w-full h-[1.5px] bg-[#707070] bg-opacity-50" />
          <div
            class="flex text-[10px] justify-between items-center font-bold text-[#58595b] py-2"
          >
            <div class="max-w-[117px]">
              <h1>{address}</h1>
            </div>
            <div>
              <h1>
                M: {contact[0]} <br />
                {contact[1] ? "P: " + contact[1] : ""}
              </h1>
            </div>
            <div>
              <a href="http://quin.design">www.quin.design</a>
            </div>
          </div>
          <div class="pb-2">
            <h1 class="font-proximaItalic text-[8px]">
              The information contained in this email is confidential,
              privileged and only for the information of the intended recipient
              and may not be used, published or redistributed without the prior
              written consent of Quin Design INC.
            </h1>
          </div>
        </div>
      </main>
    </div>
  {:else}
    <div class="min-h-screen text-black bg-white m-0 p-0">
      <main class="">
        <div
          class="md:w-1/3 w-full md:px-0 px-2 flex flex-col mx-auto space-y-4 font-proximaRegular py-12"
        >
          <div class="">
            <h1 class="text-4xl font-bold">Email Signature Without Image</h1>
          </div>
          <div class="">
            <h1>Name:<span class="text-red-600 font-bold">*</span></h1>
            <div class="md:space-x-3">
              <input
                type="text"
                name=""
                id=""
                bind:value={firstname}
                placeholder="First Name"
                class="border-2 px-3 py-1"
                required
              />
              <input
                type="text"
                name=""
                id=""
                bind:value={lastname}
                placeholder="Last Name"
                class="border-2 px-3 py-1"
                required
              />
            </div>
          </div>
          <div>
            <h1>Position: <span class="text-xs">(Optional)</span></h1>
            <input
              type="text"
              name=""
              id=""
              bind:value={position}
              placeholder="Manager"
              class="border-2 px-3 py-1"
            />
          </div>
          <div>
            <h1>Address:<span class="text-red-600 font-bold">*</span></h1>
            <select bind:value={address} class="border-2 p-2">
              <option value="1729 Irving Blvd, Suite 103 Dallas, TX 75243 USA"
                >1729 Irving Blvd, Suite 103 Dallas, TX 75243 USA</option
              >
              <option value="Dallas X Hyderabad">Dallas X Hyderabad</option>
            </select>
          </div>
          <div>
            <h1>Contact:</h1>
            <div class="flex space-x-3">
              <div>
                <span
                  >Mobile: <span class="text-red-600 font-bold">*</span></span
                >
                <input
                  type="tel"
                  name=""
                  id=""
                  bind:value={mobile}
                  placeholder="+919876543210"
                  class="border-2 px-3 py-1"
                  required
                />
              </div>
              <div>
                <span>Phone:<span class="text-xs">(Optional)</span> </span>
                <input
                  type="tel"
                  name=""
                  id=""
                  bind:value={phone}
                  placeholder="+919876543210"
                  class="border-2 px-3 py-1"
                />
              </div>
            </div>
          </div>
          <div>
            <button
              class="btn-primary text-gray-800 font-bold my-4"
              on:click={formHandler}>Generate Signature</button
            >
          </div>
        </div>
      </main>
    </div>
  {/if}
</section>
