const passwordSizeCheck = (word) => {
  if (word.length >= 6 && word.length <= 20) {
    return true;
  }
  return false;
};

const passwordHandler = (password) => {
  if (passwordSizeCheck(password) && password !== "") {
    return true;
  }
  return false;
};

const repasswordHandler = (password, repassword) => {
  // console.log(passwordHandler(password));
  // console.log(password);
  // console.log(repassword);
  if (passwordHandler(password) && password === repassword) {
    return true;
  }
  return false;
};

const confirmHandler = (password, repassword) => {
  if (passwordHandler(password) && repasswordHandler(password, repassword)) {
    return true;
  }
  return false;
};

module.exports = {
  passwordSizeCheck,
  passwordHandler,
  repasswordHandler,
  confirmHandler,
};
