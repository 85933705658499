<script>
  import EmailSignature from "../components/emailSignature.svelte";
  import { user } from "../resources/email";
  let choice = "--CHOOSE--";
  let data;
  $: {
    if (choice !== "--CHOOSE--") {
      user.forEach((val) => {
        if (val.fullname === choice) {
          data = val;
          return;
        }
      });
    }
    console.log(data);
  }
</script>

<main>
  {#if data}
    <EmailSignature {...data} />
  {:else}
    <main
      class="min-w-screen min-h-screen font-proximaRegular flex justify-start items-center flex-col space-y-4 py-12 bg-white text-black"
    >
      <div class="">
        <h1 class="text-4xl font-bold">Email Signature With Image</h1>
      </div>
      <div>
        <label for="user">Name: </label>
        <select
          name="user"
          id="user"
          bind:value={choice}
          class="border-2 p-2 rounded-md"
        >
          <option value="--CHOOSE--" disabled>--CHOOSE--</option>
          {#each user as val}
            <option value={val.fullname}>{val.fullname}</option>
          {/each}
        </select>
      </div>
    </main>
  {/if}
</main>
