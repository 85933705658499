<script>
  import DownloadApp from "./DownloadApp.svelte";
  let quinSquare = "/assets/images/quin-logo-square.svg";
  let bell = "/assets/images/bell-icon.svg";
  let shield = "/assets/images/shield-icon.svg";
  let nav = "/assets/images/nav-icon.svg";
  let avatar = "/assets/images/avatar.svg";

  let clickStatus = false;

  export let profileImage;
  export let name;

  function getApp() {
    clickStatus = !clickStatus;
    if (clickStatus) {
      document
        .getElementById("beforeDownload")
        .classList.replace("flex", "hidden");
      document
        .getElementById("afterDownload")
        .classList.replace("hidden", "block");
    }
  }
</script>

<div>
  <main
    id="beforeDownload"
    class="flex flex-col items-center  space-y-4 py-12 md:px-0 px-12"
  >
    <div class="flex flex-col items-center space-y-2">
      <div>
        <img
          class="w-[90px] rounded-full"
          src={profileImage ? profileImage : avatar}
          alt="logo"
        />
      </div>
      <h1 class="font-proximaBold text-center">
        You’ve been successfully added as an Emergency Contact for {name}
      </h1>
    </div>
    <div class="flex flex-col items-center space-y-4">
      <div class="flex flex-col items-center space-y-4">
        <img src={quinSquare} alt="Quinlogo" class="w-12 h-12" />
        <h1 class="font-proximaBold">Get the Quin Ride App</h1>
      </div>
      <div class="space-y-4 flex flex-col ">
        <div class="flex space-x-4 items-center">
          <div
            class="flex-none bg-gradient-to-l from-[#4ef8e8] to-[#27ada1] rounded-full h-8 w-8 flex justify-center items-center"
          >
            <img src={bell} alt="bell" class="h-6 w-6 aspect-square p-1" />
          </div>
          <h1>Real time alerts in the event of an emergency</h1>
        </div>
        <div class="flex space-x-4  items-center">
          <div
            class="flex-none bg-gradient-to-l from-[#4ef8e8] to-[#27ada1] rounded-full h-8 w-8 flex justify-center items-center"
          >
            <img src={nav} alt="nav" class="h-6 w-6 aspect-square p-1" />
          </div>
          <h1>Easy navigation to the location of emergency</h1>
        </div>
        <div class="flex space-x-4 items-center ">
          <div
            class="flex-none bg-gradient-to-l from-[#4ef8e8] to-[#27ada1] rounded-full h-8 w-8 flex justify-center items-center"
          >
            <img
              src={shield}
              alt="shield"
              class="h-6 w-6 aspect-square p-1"
            />
          </div>
          <h1>Updates: Rider Status and Dispatch of Emergency Services</h1>
        </div>
      </div>
      <div>
        <button
          on:click={getApp}
          class="btn-primary text-white blue-gradient px-8 py-1 text-sm font-proximaBold"
          >GET APP</button
        >
      </div>
      <div />
    </div>
  </main>
  <div id="afterDownload" class="hidden">
    <DownloadApp />
  </div>
</div>
