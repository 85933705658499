<script>
  export let name;
  export let position;
  export let address;
  export let contact;
  export let image;
</script>

<div class="min-h-screen bg-white text-black">
  <main
    class="border-[1px] pl-4 pt-4 text-[#707070] w-[600px] font-proximaRegular mx-auto my-8 relative"
  >
    <div class="w-3/4">
      <div class="flex space-x-6 justify-start">
        <div>
          <img
            src="/assets/images/email-logo.gif"
            alt="logo"
            class="max-w-[75px]"
          />
        </div>
        <div>
          <h1
            class="text-3xl text-[#707070] font-proximaRegular font-semibold leading-8"
          >
            {name[0]} <br />
            {name[1]}
          </h1>
          <p class="font-proximaRegular text-[12px] font-semibold">
            {position !== null ? position : ""}
          </p>
        </div>
      </div>
      <div class="py-3 flex space-x-2">
        <a href="https://www.instagram.com/quin.design/?hl=en">
          <img
            src="/assets/images/instagram.svg"
            alt="icons"
            class="w-[14px]"
          />
        </a>
        <a href="https://in.linkedin.com/company/quindesign">
          <img src="/assets/images/linkedin.svg" alt="icons" class="w-[14px]" />
        </a>
        <a href="https://www.facebook.com/Quin.DesignFB/">
          <img src="/assets/images/facebook.svg" alt="icons" class="w-[14px]" />
        </a>
        <a
          href="https://www.youtube.com/channel/UC23UdFuJzMpQ9IfIBxVW_Ww/playlists?app=desktop"
        >
          <img src="/assets/images/youtube.svg" alt="icons" class="w-[14px]" />
        </a>
      </div>
      <div class="w-full h-[1.5px] bg-[#707070] bg-opacity-50" />
      <div
        class="flex text-[10px] justify-between items-center font-bold text-[#58595b] py-2"
      >
        <div class="max-w-[117px]">
          <h1>{address}</h1>
        </div>
        <div>
          <h1>{contact[0]} <br /> {contact[1] ? contact[1] : ""}</h1>
        </div>
        <div>
          <a href="http://quin.design">www.quin.design</a>
        </div>
      </div>
      <div class="pb-2">
        <h1 class="font-proximaItalic text-[8px]">
          The information contained in this email is confidential, privileged
          and only for the information of the intended recipient and may not be
          used, published or redistributed without the prior written consent of
          Quin Design INC.
        </h1>
      </div>
    </div>
    <div class="absolute inset-y-0 right-0">
      <img src={image} alt="" class="max-h-full" />
    </div>
  </main>
</div>
